<script>
  import {
    project,
    postData,
    orgOperators,
    mobileNavHeight,
  } from "$lib/services/store";
  import { translations } from "$lib/data/translations";
  import Icons from "$lib/main/components/Icons.svelte";
  import Terms from "$lib/admin/components/blocks/helpers/Terms.svelte";
  import Block from "$lib/admin/components/blocks/helpers/Block.svelte";

  let yScroll;
  let operator = null;

  $: {
    if (
      $postData &&
      $postData.parent_id == $project.data.categoryPages.reviews.id
    ) {
      operator = $orgOperators.find(
        (x) => x.name_lower == $postData.slug.split("/").pop()
      );
    }
  }
</script>

<svelte:window bind:scrollY={yScroll} />

{#if yScroll >= 100 && !(operator && (operator?.rating[$project.key][$project.settings.market]["closed"] || !operator?.rating[$project.key][$project.settings.market]["license"] || operator?.rating[$project.key][$project.settings.market]["upcoming"]))}
  <div
    class={`mobile-nav p-${$project.key} t-${$project.key}`}
    class:operator-cta={$postData.parent_id ==
      $project.data.categoryPages.reviews.id}
    bind:clientHeight={$mobileNavHeight}
  >
    {#if $postData.parent_id == $project.data.categoryPages.reviews.id && operator}
      {@const ctaLink = `/${$project.settings.redirectUrl}/${operator.name_lower}`}
      <Block>
        <a
          class="button"
          target="_blank"
          rel="nofollow noreferrer"
          href={ctaLink}
          data-element="scrollable"
          data-operator={operator.name_lower}
          data-type="button"
        >
          {translations["To"][$project.settings.lang] + " " + operator.name}</a
        >
        <i><Terms {operator} /></i>
      </Block>
    {:else}
      <ul>
        {#each $project.menu.mobileMenu as menuItem}
          <li>
            <a href={menuItem.path}>
              <Icons
                name={menuItem.icon}
                strokeWidth="2"
                width="24"
                height="24"
              />
              <span>{menuItem.name}</span>
            </a>
          </li>
        {/each}
      </ul>
    {/if}
  </div>
{/if}
