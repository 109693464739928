<script>
  export let type = null
  export let html = null
</script>

<div class={"announcement" + (type ? " " + type : "")}>
  {@html html}
</div>

<style>
  .announcement {
    background: #eee;
    padding: .5rem;
    font-size: .75rem;
    color: #999;
    text-align: center;
  }
  :global(.announcement .responsible-gaming) {
    background: #eee;
    padding: .5rem;
    font-size: .75rem;
    color: #999;
    text-align: center;
  }
</style>