export function initializeCookieYesEventListener() {
  document.addEventListener("openCookiePreferences", function () {
    const ckyContainer = document.querySelector(".cky-consent-container");
    const ckyPreferenceBtn = document.querySelector(".cky-btn-customize");

    try {
      if (ckyContainer) {
        ckyContainer.classList.remove("cky-hide");
      }

      if (ckyPreferenceBtn) {
        ckyPreferenceBtn.click();
      }
    } catch (error) {
      console.error("Failed to open CookieYes preference panel:", error);
    }
  });
}

export function attachTriggerLink() {
  const triggerLink = document.querySelector(
    '[data-trigger="cookiePreferences"]'
  );

  if (triggerLink) {
    triggerLink.addEventListener("click", function (event) {
      event.preventDefault();
      document.dispatchEvent(new Event("openCookiePreferences"));
    });
  }
}
